import React, { useState, useEffect, useContext } from 'react';
import Pagination from '../../organismas/pagination';
import { formatByString } from '../../../utils/date';
import { VOC_STATUS_TYPES, WORK_SITE } from '../../../constants';
import Select from '../../atoms/select';
import DatePicker from '../../organismas/input/datepicker';
import LimitSelect from '../../atoms/select/limit';
import { useStores } from '../../../store/mobx';
import { observer } from 'mobx-react';
import { isNull } from 'lodash';
import { useSelector } from 'react-redux';
import CustomCalendar from '../../organismas/customCalendar';
import { th } from 'date-fns/locale';
import { MessageContext, MessageImpl } from '../../../routes';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';
import Core from '../../../apis/core';
import FileSaver from 'file-saver';

const VocList = observer((props: any) => {
  const {vocStore: store} = useStores();
  const {headerStore: headerStore} = useStores();
  const [reload, setReload] = useState<boolean>(false);
  const baseUrl = '/lodged/maintenance';
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user
  }));
  const [sortConfig, setSortConfig] = useState<{ key: string, direction: string }>({ key: "", direction: "" });
  const [showChoiceMng, setShowChoiceMng] = useState<boolean>(false);
  const [items, setItems] = useState<any>([]);
  const [isShowDownloadPop, setIsShowDownloadPop] = useState<boolean>(false);
  const [downloadText, setDownloadText] = useState<string>('');
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  useEffect(() => {
    headerStore.getAuthBuilding();
    headerStore.getAuthMenuRole('3-2');
    headerStore.getAuthRoomRole();
    headerStore.getAuthBuilding();
    
    (async () => {
      getRole();
    })();
    return () => {
      if (!store.findTabs(`${baseUrl}`)) {
        store.destory();
      }
    }
  }, [reload,headerStore.workSite]);

  const getRole = async () => {
    headerStore.isLoading = true;
    if(headerStore.mobileRoleFlag === true){
      store.auth_Menu = 'Y';
    }else{
      store.auth_Menu = 'N';
    }
    store.employee_number = userInfo.employeeNumber
    store.work_site = headerStore.workSite;
    store.roomtype = headerStore.paramAuthRoomTypeRole;
    if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0) {
        store.authBuilding = headerStore.paramAuthBuildingRole;
    }
    await store.getItems();
    setReload(true);
    headerStore.isLoading = false;
  }
  //소팅
  const requestSort = (key: string) => {
    let direction = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
        direction = 'desc';
    }

    setSortConfig({ key, direction });
    if (direction === 'asc') {
        store.ordering = '-' + key;
    } else {
        store.ordering = key;
    }

    store.getItems();
  }

  // 소팅 class
  const getClassNamesFor = (name: string) => {
    if (!sortConfig) {
        return;
    }
    return sortConfig.key === name ? sortConfig.direction : '';
  };

  const onSearch = async () => {
    headerStore.isLoading = true;
    await store.getItems();
    headerStore.isLoading = false;
  }

  useEffect(() => {
    (async () => {
      headerStore.isLoading = true;
      await store.getItems();
      headerStore.isLoading = false;
    })();
  }, [store.limit]);

  const onAdd = () => {
    store.newItem();
    props.history.replace(`${baseUrl}/create`);
  };

  const onGoPage = (item: any) => {
    store.selectedItem(item);
    props.history.replace(`${baseUrl}/${item.id}`)
  };

  // 다운로드
  const xlsDownload = async () => {
    if(downloadText.trim() == '') {
      info("다운로드 사유를 입력하지 않았습니다.");
      return;
    }
    
    try {
      headerStore.isLoading = true;
      const payload: any = {
        employee_number: userInfo.employeeNumber,
        reason: downloadText,
        worksite: store.work_site,
        firstDate: store.created_after,
        secondDate: store.created_before,
      };

      console.log(payload);
      //let data;
      let { data } = await Core.xlsvoclist(payload);
      //console.log(payload);
      if (data) {
        FileSaver.saveAs(`${process.env.REACT_APP_MEDIA}` + data);
      }
      setIsShowDownloadPop(false);
      setDownloadText('');
      headerStore.isLoading = false;
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return;
      } else if (status === 500) {
        window.location.href = '/500';
        return;
      }
      // console.log(status, data);
    }
  };

  return (
    <>
    <div className={['tab_contents', store.tabIndex === 0 ? 'on' : ''].join(' ')}>
      <h4>유지운영내역</h4>
      <div className="table_modify_list">
        <dl className="info">
          <dt>정보</dt>
          <dd>
            <Select
              className="form_control"
              options={store.searchTypes}
              value={store.searchType}
              onChange={(e: any) => store.searchType = Number(e.target.value)}
            />
            <input
              type="text"
              className="form_control"
              value={store.search}
              onKeyUp={e => (e.key === 'Enter' ? onSearch() : () => {})}
              onChange={e => store.search = e.target.value}
            />
          </dd>
        </dl>
        <dl className="date">
          <dt>조회기간</dt>
          <dd className="">
            <div className="datepicker_searchwrap form_inline" style={{marginRight: '10px'}}>
              {/* <DatePicker
                value={store.created_after}
                width={250}
                onChange={(date: string) => store.created_after = date}
                isPrevMonthView={true}
                max={store.created_before}
              /> */}
              <CustomCalendar
                max={store.created_before}
                value={store.created_after}
                onChange={(date:string) => store.created_after = date}
              ></CustomCalendar> 
            </div>
            <span className="dash">~</span>
            <div className="datepicker_searchwrap form_inline" style={{marginRight: '10px'}}>
              {/* <DatePicker
                value={store.created_before}
                width={250}
                onChange={(date: string) => store.created_before = date}
                isPrevMonthView={true}
                min={store.created_after}
              /> */}
              <CustomCalendar
                min={store.created_after}
                value={store.created_before}
                onChange={(date:string) => store.created_before = date}
              ></CustomCalendar> 
            </div>
            <button type="button" className="btn btn_sm btn_gray" onClick={onSearch}>검색</button>
          </dd>
        </dl>
      </div>

      <h4>유지운영내역 리스트
        <div className="right_area">
          <LimitSelect
            className="form_control mr10"
            limit={store.limit}
            onChange={(limit: number) => {
              store.limit = limit;
              store.offset = 0;
            }}
          />

          <button
            className="btn btn_sm btn_darkblue"
            onClick={() => {
              setIsShowDownloadPop(true);
            }}
          >
            다운로드
          </button>
        </div>
      </h4>
      
      <div className="table_normal_list event_on box_scroll_x">
        <table>
        <caption className="sr_only">숙소유형별 이용자범위 설정 등록현황</caption>
        <colgroup>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="20%"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
        </colgroup>
        <thead>
        <tr>
            <th>사업장</th>
            <th><button type="button" onClick={() => requestSort('is_complete')} className={`table_sortBtn ${getClassNamesFor('is_complete')}`}>진행상태</button></th>
            <th>구분</th>
            <th>1차분류</th>
            <th>2차분류</th>
            <th>3차분류</th>
            <th>4차분류</th>
            <th>발생장소</th>
            <th>내용</th>
            <th>완료일</th>
            <th>작성자</th>
            <th>작성일</th>
            <th>이관대상자</th>
        </tr>
        </thead>
        <tbody>
        {!store.items.length ? (
          <tr>
            <td colSpan={12} className="blank_area type_white">
              <p className="txt_blank">데이터가 없습니다.</p>
            </td>
          </tr>
        ) : (
          store.items.map((item: any, index: number) => {
            let placeName = '';
            if(item.places_etc && item.places_etc != '') {
              placeName = '기타 > ' + item.places_etc + ' > ' + item.places_etc2;
            }else if (item.places.length > 0) {
              const place = item.places[0];
              if (item.places.length === 1) {
                placeName =  `${place.building_name} > ${place.building_dong} > ${place.room_name}`;
              } else {
                placeName =  `${place.building_name} > ${place.building_dong} > ${place.room_name}외 ${item.places.length - 1}곳`;
              }
            }
            return (
            <tr key={index}>
              <td
                onClick={() => onGoPage(item)}
                style={{ cursor: 'pointer' }}
              >
                {WORK_SITE[item.work_site]}
              </td>
              <td
                onClick={() => onGoPage(item)}
                style={{ cursor: 'pointer' }}
              >
                <span className={['state_type', item.is_complete ? 'type3' : ''].join(' ')}>
                  {item.is_complete ? '완료' : '처리중'}
                </span>
              </td>
              <td
                onClick={() => onGoPage(item)}
                style={{ cursor: 'pointer' }}
              >
                {item.is_maintenance ? '유지보수' : 'Voc'}
              </td>
              <td
                onClick={() => onGoPage(item)}
                style={{ cursor: 'pointer' }}
              >
                {item.category1}
              </td>
              <td
                onClick={() => onGoPage(item)}
                style={{ cursor: 'pointer' }}
              >
                {item.category2}
              </td>
              <td
                onClick={() => onGoPage(item)}
                style={{ cursor: 'pointer' }}
              >
                {item.category3}
              </td>
              <td
                onClick={() => onGoPage(item)}
                style={{ cursor: 'pointer' }}
              >
                {item.category4}
              </td>
              <td
                onClick={() => onGoPage(item)}
                style={{ cursor: 'pointer' }}
              >
                {placeName}
              </td>
              <td
                onClick={() => onGoPage(item)}
                style={{ cursor: 'pointer' }}
              >
                <span className="txt_ellipsis">{item.title}</span>
              </td>
              <td
                onClick={() => onGoPage(item)}
                style={{ cursor: 'pointer' }}
              >
                {formatByString(item.completed_at, 'yyyy.MM.dd HH:mm')}
              </td>
              <td
                onClick={() => onGoPage(item)}
                style={{ cursor: 'pointer' }}
              >
                {item.author_name}
              </td>
              <td
                onClick={() => onGoPage(item)}
                style={{ cursor: 'pointer' }}
              >
                {formatByString(item.created_at, 'yyyy.MM.dd')}
              </td>
              <td><button className="btn btn_xs btn_gray btn_outline" onClick={()=> {setShowChoiceMng(true); setItems(item.managers)}}>보기</button></td>
            </tr>
          )})
        )}
        </tbody>
        </table>
      </div>  {/*table*/}

      <div className="board_paging">
        <ul>
          <Pagination
            offset={store.offset}
            limit={store.limit}
            totalPage={store.totalPage}
            setOffset={(offset: number) => store.setOffset(offset)} />
        </ul>
      </div>
      {/*board_paging*/}
      {headerStore.writeRoleFlag?
      <div className="submit al">
          <button className="btn btn_lg btn_darkblue" onClick={onAdd}>유지보수 등록</button>
      </div>
      :
      <></>
      }
    </div>

    {/* 이관 대상 팝업 */}
    <div id="pop_companion" className="pop_dim_wrap pop_modal pop_sm" style={{display: showChoiceMng? "block": "none" }}>
          <div className="pop_wrap">
              <div className="title_wrap">
                  <h3>이관 대상</h3>
              <button className="pop_btn_close pop_close" onClick={() => setShowChoiceMng(false)}>닫기</button>
              </div>
              <div className="contents_wrap">
                <div className="process_list">
                  {items.length <= 0 ? (
                    <dd className="blank_area type_white"><p className="txt_blank">데이터가 없습니다.</p></dd>
                  ) : (
                    <dl className="">
                      <dd className="on">
                      {items.map((manager : any, index : number) => (
                          <a className="item mb10">{manager.name}</a>
                      ))}
                      </dd>
                    </dl>
                  )}
                </div>
              </div>
              <div className="button_wrap btn_length_1">
                <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={() => setShowChoiceMng(false)}>확인</button>
              </div>
          </div>
      </div>
      {/* 다운로드 사유 팝업 */}
      <div
        id="pop_download"
        className="pop_dim_wrap pop_modal pop_sm"
        style={{ display: isShowDownloadPop ? 'block' : 'none' }}
      >
        <div className="pop_wrap">
          <div className="title_wrap">
            <h3>다운로드 사유</h3>
            <button
              className="pop_btn_close pop_close"
              onClick={() => {
                setDownloadText('');
                setIsShowDownloadPop(false);
              }}
            >
              닫기
            </button>
          </div>
          <div className="contents_wrap">
            <div className="process_list">
              <dl>
                <dt>사유</dt>
                <dd>
                  <textarea
                    className="form_control"
                    value={downloadText}
                    onChange={(e: any) => setDownloadText(e.target.value)}
                  ></textarea>
                </dd>
              </dl>
            </div>
          </div>
          <div className="button_wrap btn_length_1">
            <button
              type="button"
              className="btn btn_lg btn_darkblue pop_close"
              onClick={() => {
                xlsDownload();
              }}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </>
  );
});

export default VocList;
